<template>
  <div class="ma-3" elevation="10">
    <v-card>
    <v-toolbar class="mb-4"
                      :color="$colors[0]"
                      dark>
                      <span class="Poppins-Bold pl-0">Nouvelle vente particulier</span>
                  </v-toolbar>
      <v-row>
        <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
            <v-dialog
                v-model="dialog"
                persistent
                max-width="500"
                >
                <v-card>
                    <v-card-title class="text-h5">
                    Nouvelle vente particulier
                    </v-card-title>
                    <v-card-text>
                        <v-alert v-if="error==true && loading==false" type="error">
                            {{messageDialoge}}
                        </v-alert>
                        <v-alert v-if="error==false && loading==false" type="success">
                            {{messageDialoge}}
                        </v-alert>
                        <v-flex  class="text-center">
                            <v-progress-circular v-if="loading==true"
                                :size="150"
                                :width="7"
                                color="primary"
                                indeterminate
                                >
                                Patientez  ...
                            </v-progress-circular>
                        </v-flex>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="loading==false"
                        color="green darken-1"
                        text
                        @click="dialog = false"
                    >
                        FERMER
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
    <v-form class="ma-4" ref="form" lazy-validation>
      <v-row>
        <!-- Nom du client -->
        <v-col sm="4" md="4" lg="6">
          <v-text-field
            :rules="[required('un nom valide'),rules.isString]"
            required
            outlined
            v-model="editedForm.nomClient"
            label="Nom client"
          ></v-text-field>
        </v-col>

        <!-- Prenom du client -->
        <v-col sm="4" md="4" lg="6">
          <v-text-field
            :rules="[required('un prenom valide'),rules.isString]"
            required
            outlined
            v-model="editedForm.prenomClient"
            label="Prénom client"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <!-- Email du client -->
        <v-col sm="4" md="4" lg="6">
          <v-text-field
            :rules="[required('un email valide'),rules.emailRules]"
            validate-on-blur
            required
            outlined
            v-model="editedForm.emailClient"
            label="Email client"
          ></v-text-field>
        </v-col>
        <!-- Telephone du client -->
        <v-col sm="4" md="4" lg="6">
          <v-text-field
            :rules="[
              required('un numéro de téléphone valide'),
              rules.telMobileIsValid,rules.noWhiteSpace
            ]"
            required
            outlined
            v-model="editedForm.numeroTelephoneClient"
            label="Téléphone client"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <!-- Energies -->
        <v-col sm="4" md="4" lg="6">
          <v-select
            :rules="[required('un type d\'energie valide')]"
            required
            outlined
            v-model="editedForm.energie"
            :items="optionsEnergie"
            label="Energie(s)"
          ></v-select>
        </v-col>
        <!-- Fournisseur -->
        <v-col sm="4" md="4" lg="6">
          <v-select
            v-if="editedForm.energie"
            :rules="[required('un fournisseur valide')]"
            required
            outlined
            v-model="editedForm.fournisseur"
            :items="optionsFournisseur"
            label="Fournisseur"
          ></v-select>
        </v-col>
        <!-- Offre -->
        <v-col md="4" sm="4" lg="6">
          <v-select
            v-if="editedForm.fournisseur"
            :rules="[required('une offre valide')]"
            required
            outlined
            v-model="editedForm.offre"
            :items="optionsOffres"
            label="Offre"
          ></v-select>
        </v-col>
        <!--Puissance Elecocite  -->
        <v-col cols="12" md="3" lg="6">
          <v-select
            v-if="editedForm.fournisseur == 'ELECOCITE'"
            required
            outlined
            v-model="editedForm.puissance"
            :items="optionsPuissance"
            label="Puissance"
            suffix="kVA"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" md="4" lg="6">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :nudge-top="-45"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                hint="format AAAA-MM-JJ"
                v-model="editedForm.date"
                label="Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editedForm.date"
              :min="dateDebut"
              @input="menu = false"
              locale="fr-fr"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col md="4" sm="4" lg="6">
          <v-select
            :rules="[required('un statut valide.')]"
            required
            outlined
            v-model="editedForm.status"
            :items="optionsStatus"
            label="Statut"
          ></v-select>
        </v-col>
        <!-- Actions -->
      </v-row>
      <!-- Bouton Ajouter Vente -->
      <v-row>
        <v-spacer></v-spacer>
        <v-col sm="4" md="4" lg="3">
          <v-btn
            @click="clearForm"
            depressed
            large
            color="error"
            text
            class="ma-auto"
            width="90%"
          >
            <span class="Poppins-Bold">Réinitialiser</span>
          </v-btn>
        </v-col>
        <v-col sm="4" md="4" lg="3">
          <v-btn
            @click="ajouterVente"
            depressed
            elevation="1"
            large
            :color="$colors[0]"
            class="ma-auto text-white"
            width="90%"
          >
            <span class="Poppins-Bold">Ajouter</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import dialogeModal from '../../components/ui/dialogeModal.vue'
export default {
  name: 'VenteParticuliers',
  components: {
    dialogeModal,
  },
  data() {
    return {
      dialog: false,
      error: false,
      loading: false,
      messageDialoge: "L'e-mail a été envoyé avec succès",
      //valeurs des champs par default
      defaultForm: {
        nomClient: '',
        prenomClient: '',
        emailClient: '',
        numeroTelephoneClient: '',
        energie: null,
        fournisseur: null,
        offre: null,
        puissance: null,
        status: null,
        date: new Date().toISOString().substr(0, 10),
      },
      //valeurs de champs modifiées
      editedForm: {
        nomClient: '',
        prenomClient: '',
        emailClient: '',
        numeroTelephoneClient: '',
        energie: null,
        fournisseur: null,
        offre: null,
        puissance: null,
        status: null,
        date: new Date().toISOString().substr(0, 10),
      },

      //paramettres pour input de date
      dateDebut: this.getMinDate(),
      menu: false,

      //resultat de submit
      resultAjouterVenete: null,

      //options listes déroulantes
      optionsPuissance: [3, 6, 9, 12, 15, 18, 24, 30, 36],
      optionsFournisseur: ['WEKIWI', 'OHM'],
      //optionsFournisseur: ['WEKIWI', 'OHM', 'EDF','ELMY', 'ELECOCITE'],
      optionsEnergie: ['Gaz', 'Elec', 'Dual'],
      optionsStatus: [
        'En attente délais de rétractation',
        'Validée',
        'retractée',
      ],
      //liste des fournisseurs et les offres proposées
      fournisseurs: {
        WEKIWI: { options: ['Prix Indexe', 'Fixe'] },
        ELECOCITE: { options: ['Indexe TRV'] },
        EDF: { options: ['TRV'] },
        ELMY: { options: ['Indexe TRV'] },
        OHM: { options: ['Classique', 'Petite conso'] },
      },
      //règles de validation forme propositions
      required(finPhrase) {
          return (value) => !!value || `Veuillez saisir ${finPhrase}`
        },
      rules: {
      isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
      noWhiteSpace: value => !(/\s/.test(value)) || "Ce champ ne doit pas être constitué d'espace",        
        //vérification de numéro mobile
        telMobileIsValid(tel) {
          //retire les valeurs non-numériques
          tel = tel.replace(/[\.,\s]/g, '')

          return (
            /^(\+33|0033|0)(6|7)[0-9]{8}$/g.test(tel) ||
            `Veuillez saisir un numéro de téléphone valide.`
          )
        },
        emailRules: (v) => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || "l'adresse mail est invalide",

      },
  }
  },
  created() {
    this.initializeForm()
    //this.verifyApplicationDataSecurity()
  },

  methods: {
    getMinDate() {
      var current = new Date()
      current.setDate(current.getDate() - 7)
      var day = 0
      if (current.getDate() < 10) {
        day = '0' + current.getDate()
      } else {
        day = current.getDate()
      }
      var month = 0
      if (current.getMonth() + 1 < 10) {
        month = '0' + (current.getMonth() + 1)
      } else {
        month = current.getMonth() + 1
      }
      const date = current.getFullYear() + '-' + month + '-' + day
      return date
    },

    clearForm() {
      this.resultAjouterVenete = null
      this.initializeForm()
      this.$refs.form.resetValidation()
    },
    //met les valeur par default dans les champs
    initializeForm() {
      this.resultAjouterVenete = null
      this.editedForm = Object.assign({}, this.defaultForm)
    },
    ajouterVente() {
      
      if (!this.$refs.form.validate()) return
      let self = this
      self.dialog = true
      self.loading = true
      self.error = false
      var formData = new FormData()
      formData.append('date', this.editedForm.date)
      formData.append('Nom_du_client', self.editedForm.nomClient +' '+ self.editedForm.prenomClient)
      formData.append('Email', self.editedForm.emailClient)
      formData.append('Telephone', self.editedForm.numeroTelephoneClient)
      formData.append('Fournisseur', self.editedForm.fournisseur)
      formData.append('Energie', self.editedForm.energie)
      formData.append('Offre', self.editedForm.offre)
      formData.append('Status', self.editedForm.status)
      formData.append('kva', self.editedForm.puissance)
      formData.append('token', this.getToken('token'))
       axios({
            url: process.env.VUE_APP_URL_API_CLIENT + 'ajouterVenteParticulier/',
            method: 'POST',
            data: formData,
        })
        .then((res) => {
          if (res.data.result == true) {
            
            self.dialog = true
            self.loading = false
            self.error = false
            var mess = "-------------------------------------\n"+
              localStorage.getItem('email')+" a créer une nouvelle vente particulière \""+self.editedForm.energie+"\":"+
              "\n email client:"+self.editedForm.emailClient+
              "\n N° téléphone:"+self.editedForm.numeroTelephoneClient+
              "\n date: "+self.editedForm.date+
              "\n----------------------------------------"
            //this.envoyerNotification(mess)
            self.messageDialoge="Votre vente particulier a été ajouté avec succès !"
            //ajouter
            self.resultAjouterVenete = 1
          } else  if (res.data.result == false) {
            //error de selection de fornisseur fromù data base
            self.dialog = true
            self.loading = false
            self.error = true
            self.messageDialoge="Erreur, veuillez vérifier vos données, puis réessayer !"
            self.resultAjouterVenete = 3
          } else if (res.data.result === 'permission') {
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        })
        .catch((err) => {
          
          console.log(err)
          self.dialog = true
          self.loading = false
          self.error = true
          self.messageDialoge="Une erreur est survenue ! Veuillez réessayer plus tard"
        })
    },
    async envoyerNotification(message){
        var formData = new FormData()
        formData.append('msg', message)
        axios({
            url:'https://app.volto.fr/slackBotSendMessage/',
            method: 'POST',
            data: formData,
        }).then((res) => {
        })
    },
    verifyApplicationDataSecurity() {
        let self = this
        var formData = new FormData()
        formData.append('type', localStorage.getItem('typeUser'))
        formData.append('vueAfficher', 'part')
        axios({
            url:
            process.env.VUE_APP_URL_API_CLIENT +
            'verifyApplicationDataSecurity.php',
            method: 'POST',
            data: formData,
            
        })
        .then((res) => {
            var data = JSON.parse(JSON.stringify(res.data))
            if (data[0].result === 'notLogin') {
                localStorage.setItem('vueAfficher', 'particulier')
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser')
                localStorage.removeItem('token')
                this.$router.push('/login')
            } else if (data[0].result === 'accessDenied') {
                localStorage.setItem('vueAfficher', 'particulier')
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser')
                localStorage.removeItem('token')
                this.$router.push('/login')
            }
        })
        .catch((err) => {
            console.log(err)
        })
    },
    
  },
  computed: {
    //rempit la liste déroulante des offres en fonction de fournisseur choisi
    optionsOffres() {
      const fournisseurChoisi = this.editedForm.fournisseur
      //verifie si la valeur était choisi
      if (!fournisseurChoisi) return

      return this.fournisseurs[fournisseurChoisi].options
    },
  },
}
</script>
<style scoped>
@media only screen and (max-width:350px){
  .page-title{
    font-size:1.75em;
  }
}
</style>
