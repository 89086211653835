
<template>
    <div>
        <v-dialog v-model="dialog" max-width="650px">
            <v-card>
            <v-card-title>
                <span class="text-h5">Informations de la vente</span>
                
            </v-card-title>

            <v-card-text>
                <v-container class="ml-0">
                <v-form
                    ref="form"
                    lazy-validation
                >
                    <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        v-model="editedItem.Nom_du_client"
                        :rules="[rules.required,rules.isString,rules.noWhiteSpace]"
                        label="Nom du client"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            v-model="editedItem.date"
                            type="date"
                            label="Date de signature"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        v-model="editedItem.Email"
                        :rules="[rules.required,rules.emailRules]"
                        label="Email"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                        v-model="editedItem.Telephone"
                        :rules="[rules.required, rules.frenchPhone]"
                        label="Téléphone"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                        :items="fornisseurOption"
                        :rules="[rules.required]"
                        v-model="editedItem.Fournisseur"
                        label="Fournisseur"
                        v-on:change="onSelectFornisseur(editedItem.Fournisseur)"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                        :items="energierOption"
                        :rules="[rules.required]"
                        v-model="editedItem.Energie"
                        label="Énergie(s)"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                        :items="offreOption"
                        :rules="[rules.required]"
                        v-model="editedItem.Offre"
                        label="Offre"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                        :items="statusOption"
                        :rules="[rules.required]"
                        v-model="editedItem.Status"
                        label="Statut"
                        ></v-select>
                    </v-col>
                    
                    <v-col v-if="editedItem.Fournisseur=='ELECOCITE'" cols="12" sm="6" md="6">
                        <v-select
                        :items="kvaOption"
                        :rules="[rules.required]"
                        v-model="editedItem.kva"
                        label="KVA"
                        ></v-select>
                    </v-col>
                    </v-row>
                </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                Annuler
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                Enregistrer
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
            <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cette vente ?</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Confirmer</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions>
            </v-card>
        </v-dialog>
            <datatable id="table-mes-ventes-part" class="ma-8" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"  @search="rechercheData">
                
                <template v-slot:body>
                    <tbody v-if="loadingTable == false && projects.length > 0">
                        <tr v-for="project in projects" :key="project.id">
                            <td>{{project.date}}</td>
                            <td>{{project.Commerciale}}</td>
                            <td>{{project.Nom_du_client}}</td>
                            <td>{{project.Telephone}}</td>
                            <td>{{project.Email}}</td>
                            <td>{{project.Fournisseur}}</td>
                            <td>{{project.Energie}}</td>
                            <td>{{project.Offre}}</td>
                            <td>{{project.Status}}</td>
                            <td>
                                <v-row>
                                    <v-col xs="6" md="6" >
                                        <v-icon 
                                        title="Modifier"
                                        medium class="mr-2" @click="editItem(project)">
                                            mdi-pencil
                                        </v-icon>
                                    </v-col>
                                    <v-col xs="6" md="6" >
                                        <v-icon
                                        title="Supprimer"
                                         class="mr-4" medium @click="deleteItem(project)">
                                            mdi-delete
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </tbody>
                    <noDataTable class="ma-4" v-if="loadingTable==false && projects.length == 0"/>
                    <progressCircular class="ma-8" v-if="loadingTable == true"/>
                </template>
            </datatable>
            
            <v-row>
                <v-col sm="11">
                    <pagination v-if="projects.length > 0" :pagination="pagination"
                        @selectNumberOfRecords="rechercheData"
                        @first="getData(pagination.firstPage)"
                        @last="getData(pagination.lastPage)"
                        @prev="getData(pagination.prevPageUrl)"
                        @current="getData(pagination.currentPage)"
                        @next="getData(pagination.nextPageUrl)">
                    </pagination>
                </v-col>
            </v-row>
        </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    import Datatable from '../../components/dataTable/Datatable.vue'
    import Pagination from '../../components/dataTable/Pagination.vue'
    import noDataTable from "../../components/no-data-table.vue"
    import progressCircular from "../../components/progress-circular.vue"
    import axios from 'axios'
    export default{
        name: 'mesVenteParticulier',
        components: {datatable:Datatable, pagination:Pagination, noDataTable,progressCircular},
        data(){
            let sortOrders={};
            let columns = [
                {width:'10vw', label:"Date d'ajout", name:'date', sortable: true},
                {width:'10vw', label:'Commercial', name:'Commerciale', sortable: true},
                {width:'10vw', label:'Client', name:'client', sortable: true},
                {width:'10vw', label:'Téléphone', name:'numTele',sortable: true},
                {width:'10vw', label:'E-mail', name:'email_client', sortable: true},
                {width:'10vw', label:'Fournisseur', name:'fournisseur', sortable: true},
                {width:'10vw',label:'Energie', name:'energie', sortable: true},
                {width:'10vw', label:'Offre', name:'offre', sortable: true},
                {width:'10vw', label:'Statut', name:'status', sortable: true},
                {width:'10vw',label:'Actions', name:'action', sortable: true},
            ];
            columns.forEach((column)=>{
                sortOrders[column.name] = -1;
            });
            return{
                loadingTable: false,
                rules: {
                    required: value => !!value || 'Ce champ est obligatoire.',
                    minPasswordLength: v => v.length >= 8 || 'le mot de passe doit avoir 8 caractères minimum',
                    phoneLength: v => v.length == 10 || 'le numéro de téléphone doit etre constitué de 10 chiffres',
                    phoneisNumber: v => v.match(/^[0-9]+$/) != null || 'Ce champ doit être constitué uniquement de chiffres',
                    emailMatch: () => (`l'adresse mail et le mot de passe sont invalides`),
                    
                    emailRules: [
                    v => !!v || "l'adresse mail est obligatoire",
                    v => /.+@.+/.test(v) || "l'adresse mail n'est pas valide",
                    ],
                },
                editedItemId: -1,
                dialog: false,
                dialogDelete: false,
                editedItem: '',
                projects: [],
                columns: columns,
                sortKey: 'date',
                sortOrders: sortOrders,
                perPage: ['15', '20', '30'],
                tableData: {
                    statusFilter: "",
                    draw: 0,
                    length: 15,
                    search: '',
                    column: 0,
                    dir: 'desc',
                },
                pagination: {
                    perPage: ['10', '15', '30'],
                    firstPage: 1,
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },
                editedItem: {
                    id: null,
                    Date: null,
                    Nom_du_client: null,
                    Email: null,
                    Telephone: null,
                    Fournisseur: null,
                    Energie: null,
                    Offre: null,
                    Status: null,
                    kva: 3,
                },
                offreOption: [],
                kvaOption: [3, 6, 9, 12, 15, 18, 24, 30, 36],
                fornisseurOption: ['WEKIWI', 'OHM'],
                energierOption: ['Gaz', 'Elec', 'Dual'],
                statusOption: ['En attente de délais de rétractation', 'Validée', 'Retractée']
            }
        },
        watch: {
            dialog(val) {
            val || this.close()
            },
            dialogDelete(val) {
            val || this.closeDelete()
            },
        },
        async created(){
            this.loadingTable = true;
            await this.getData();
            this.loadingTable = false;
        },
        methods:{
            rechercheData({type, key}) {
                if(type=='search'){
                    var pageNumber = 1
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = key
                }
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getMesVenteParticulier/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                        if(response.data.result == true){
                            let data = response.data.data;
                            if (this.tableData.draw == data.draw) {
                                this.projects = data.data.data;
                                this.configPagination(data.data);
                            }
                        }else{
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    })
                    .catch(errors => {
                        console.log(errors);
                    });
            },
            async getData(pageNumber = 1) {
                this.tableData.draw++;
                await axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getMesVenteParticulier/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                .then(response => {
                    if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.projects = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            configPagination(data) {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.getData();
            },
            getIndex(array, key, value) {
                return array.findIndex(i => i[key] == value)
            },
            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedItemId = -1
                })
            },
            editItem(item) {
                this.editedItemId = item.id
                item.date = this.dateFormatMinus(item.date)
                this.editedItem = Object.assign({}, item) //copie le contenu d'item dans editedItem
                this.onSelectFornisseur(this.editedItem.Fournisseur);
                this.dialog = true
            },
            save() {
                this.$refs.form.validate()
                if(this.$refs.form.validate()){
                    if (this.editedItemId > -1) {
                    this.modifierVente(this.editedItem)
                    }
                }
            },
            modifierVente(vente) {
                //let self = this
                var formData = new FormData()
                for(const [key,value] of Object.entries(vente)){
                    formData.append(`${key}`,value)
                }
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'modifierVenteParticulier/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if (res.data.result == true) {
                        this.getData(this.pagination.currentPage)
                        this.close()
                    }else if (res.data.result == false) {
                        alert('Il semble que la vente ait déjà été supprimé !')
                        this.close()
                    } else if (res.data.result == 'permission')  {
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    this.showModalLoader = false
                })
                .catch((err) => {
                    this.showModalLoader = false
                    console.log(err)
                })
            },
            deleteItem(item) {
                this.editedItem = item
                this.dialogDelete = true
            },
            onSelectFornisseur(fornisseur){
                switch(fornisseur){
                    case 'WEKIWI':
                         this.offreOption=['Prix Indexe', 'Fixe'];
                        break;
                    case 'OHM':
                         this.offreOption=['Classique', 'Petite conso'];
                        break;
                    case 'ELMY':
                        this.offreOption=['Indexe TRV'];
                        break;
                    case 'ELECOCITE':
                        this.offreOption=['Indexe TRV'];
                        break;
                    default :
                        alert("vous n'avez selectionné aucun fournisseur parmis la selection")
                        break;
                }
            },
            async supprimerVente(item) {
                let self = this
                var formData = new FormData()
                formData.append('id', item.id)
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'supprimerVenteParticulier/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if (res.data.result === true) {
                        var mess = "-------------------------------------\n"+
                        "l'administrateur des ventes "+localStorage.getItem("email")+" a supprimé la vente particulier:"+
                        "\n email client:"+item.Email+
                        "\n date: "+item.Date+
                        "\n----------------------------------------"
                        
                        this.getData(this.pagination.currentPage)
                        //this.envoyerNotification(mess)
                    } else if (res.data.result === false) {
                        alert('Il semble que la vente ait déjà été supprimé !')
                        this.getData(this.pagination.currentPage)
                    } else if (res.data.result === 'permission') {
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            },
            async deleteItemConfirm() {
                await this.supprimerVente(this.editedItem)
                this.closeDelete()
            },
            close() {
                this.compteExist = false;
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedItemId = -1
                })
            },
            async envoyerNotification(message){
                var formData = new FormData()
                formData.append('msg', message)
                axios({
                    url:'https://app.volto.fr/slackBotSendMessage/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                })
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
        }
    }
</script>
<style>
    #table-mes-ventes-part th{
        background-color:#103A5E;
        color:white;
        font-weight:bold;   
    }
</style>