<template>
  <v-card class="ma-3" elevation="10">
    <v-toolbar :color="$colors[0]" dark>
      <span class="Poppins-Bold pl-0">Demande de Cotation</span>
    </v-toolbar>
    <v-form ref="form" lazy-validation>
      <v-row>
        <loadingComponent
          @closeDialog="dialog = $event"
          :loadingProps="loading"
          :messageDialogeProps="messageDialoge"
          :dialogProps="dialog"
          :errorProps="error"
        />
      </v-row>

      <v-row align="center">
        <v-col class="main-container ml-12" cols="12" sm="10" md="8" lg="12" xl="11">
          <v-radio-group id="Radio-Energie" v-model="energie" row label="ENERGIE">
            <v-radio
              label="ELEC"
              :color="$colors[2]"
              value="ELEC"
              @change="lengthCompteur = 0"
            ></v-radio>
            <v-radio
              label="GAZ"
              :color="$colors[2]"
              value="GAZ"
              @change="lengthCompteur = 0"
            ></v-radio>
          </v-radio-group>
          <v-row align="center">
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                dense
                :rules="[rules.required, rules.siretLength, rules.isNumber]"
                outlined
                @input="siretFound = false"
                v-model="siret"
                label="Siret"
                @keydown.enter.prevent="rechercheParSiret"
              >
                <template #append>
                  <v-icon :color="$colors[0]" @click="rechercheParSiret"
                    >mdi-magnify</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" v-if="siretFound">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="align-self-center text-center"
              v-if="regie.indexOf(energie) != -1"
            >
              <v-radio-group row v-model="traitemantEnedisRegie">
                <v-radio label="C'est un régie" value="oui" />
                <v-radio label="Non" value="non" />
              </v-radio-group>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required]"
                outlined
                :disabled="raisonFound == true"
                v-model="raison"
                label="Raison sociale"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required]"
                outlined
                :disabled="postalFound == true"
                v-model="adressePostale"
                label="Adresse"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required, rules.phoneisNumber, rules.phoneLength]"
                outlined
                v-model="numTele"
                label="Numéro de téléphone"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-select
                outlined
                :items="['M.', 'Mme.']"
                v-model="Civilite"
                label="Civilité"
              ></v-select>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required, rules.isString]"
                outlined
                v-model="nom"
                label="Nom"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required, rules.isString]"
                outlined
                v-model="prenom"
                label="Prénom"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.emailRules, rules.hasWhiteSpace]"
                outlined
                v-model="email"
                label="Adresse e-mail"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required, rules.isString]"
                outlined
                v-model="fonction"
                label="Poste"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required]"
                outlined
                type="date"
                v-model="echeance"
                label="Date de début de contrat"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required]"
                outlined
                type="date"
                v-model="fin_du_contrat"
                label="Date de fin du contrat"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                required
                :rules="[rules.required]"
                outlined
                type="date"
                v-model="dateCotationSouhaite"
                label="Date de cotation souhaitée"
              ></v-text-field>
            </v-col>

            <v-col cols="10" sm="6" md="4" lg="4" xl="4" v-if="energie == 'ELEC'">
              <v-text-field
                outlined
                v-if="traitemantEnedisRegie == 'non'"
                :rules="[rules.required, rules.compteurElecLength, rules.isNumber]"
                v-model="num_comp[0]"
                label="Compteur éléctrique"
              ></v-text-field>
              <v-text-field
                outlined
                v-else
                :rules="[rules.required]"
                v-model="num_comp[0]"
                label="Compteur éléctrique"
              ></v-text-field>
            </v-col>
            <v-col
              cols="10"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              v-for="(element, index) in num_comp"
              v-if="num_comp[index] != null && index > 0 && energie == 'ELEC'"
              :key="index"
            >
              <div class="d-flex">
                <v-text-field
                  outlined
                  v-if="traitemantEnedisRegie == 'non'"
                  :rules="[rules.required, rules.compteurElecLength, rules.isNumber]"
                  v-model="num_comp[index]"
                  :label="'Compteur éléctrique additionnel n°' + index"
                >
                  <template v-slot:append>
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        :color="hover ? '#F93030' : ''"
                        @click="supprimerCompteur(index)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-hover>
                  </template>
                </v-text-field>
                <v-text-field
                  outlined
                  v-else
                  :rules="[rules.required]"
                  v-model="num_comp[index]"
                  :label="'Compteur éléctrique additionnel n°' + index"
                >
                  <template v-slot:append>
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        :color="hover ? '#F93030' : ''"
                        @click="supprimerCompteur(index)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-hover>
                  </template>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="10" sm="6" md="4" lg="4" xl="4" v-if="energie == 'GAZ'">
              <v-text-field
                outlined
                v-if="traitemantEnedisRegie == 'non'"
                :rules="[rules.required, rules.isGazCompteur]"
                v-model="num_comp[0]"
                label="Compteur de gaz"
              ></v-text-field>
              <v-text-field
                outlined
                v-else
                :rules="[rules.required]"
                v-model="num_comp[0]"
                label="Compteur de gaz"
              ></v-text-field>
            </v-col>
            <v-col
              cols="10"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              v-for="(element, index) in num_comp"
              v-if="num_comp[index] != null && index > 0 && energie == 'GAZ'"
              :key="index"
            >
              <div class="d-flex">
                <v-text-field
                  outlined
                  v-if="traitemantEnedisRegie == 'non'"
                  :rules="[rules.required, rules.isGazCompteur]"
                  v-model="num_comp[index]"
                  :label="'Compteur de gaz additionnel n°' + index"
                >
                  <template v-slot:append>
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        :color="hover ? '#F93030' : ''"
                        @click="supprimerCompteur(index)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-hover>
                  </template>
                </v-text-field>
                <v-text-field
                  outlined
                  v-else
                  :rules="[rules.required]"
                  v-model="num_comp[index]"
                  :label="'Compteur de gaz additionnel n°' + index"
                >
                  <template v-slot:append>
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        :color="hover ? '#F93030' : ''"
                        @click="supprimerCompteur(index)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-hover>
                  </template>
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--body-->
      <v-row align="center" justify="center" v-if="siretFound">
        <v-col align="center" justify="center" cols="10" sm="8" md="8" lg="6" xl="6">
          <v-row align="center" justify="center">
            <v-col align="center" justify="center" cols="10" sm="6" md="6" lg="6" xl="6">
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10" sm="8" md="6" lg="6" xl="6">
          <v-row align="center" justify="center"> </v-row>
        </v-col>
      </v-row>
      <!-- type offre -->
      <v-row
        v-if="
          (energie == 'ELEC' && siretFound == true) ||
          (energie == 'GAZ' && siretFound == true)
        "
        align="center"
        justify="center"
      >
        <v-col cols="10" sm="8" md="8" lg="6">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row align="center" justify="center">
                <v-btn
                  depressed
                  elevation="1"
                  :color="$colors[0]"
                  class="text-white mb-4"
                  @click="ajouterCompteur"
                >
                  <span class="Poppins-Bold">Ajouter un compteur</span>
                </v-btn>
              </v-row>
            </v-col>
            <v-row class="pl-4" v-if="energie == 'ELEC'">
              <v-col cols="5" sm="3" md="3" lg="4">
                <p class="mb-0" style="color: gray; font-size: 12px">Type d'offre</p>
                <v-checkbox
                  class="mt-0 Poppins-Bold"
                  v-model="formElec.typeOffre"
                  label="Marché"
                  :color="$colors[2]"
                  value="Marché"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="5" sm="3" md="3" lg="4">
                <v-checkbox
                  class="Poppins-Bold"
                  v-model="formElec.typeOffre"
                  label="Arenh"
                  :color="$colors[2]"
                  value="Arenh"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="5" sm="3" md="3" lg="4">
                <v-checkbox
                  class="Poppins-Bold"
                  v-model="formElec.typeOffre"
                  label="DECOTE ARENH"
                  :color="$colors[2]"
                  value="DECOTE ARENH"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <!-- input file -->
      <v-row align="center" justify="center" v-if="siretFound">
        <v-col cols="10" sm="8" md="8" lg="6">
          <div
            class="flex w-full h-screen items-center justify-center text-center"
            id="app"
          >
            <v-select
              class=""
              v-model="fileType"
              :items="items"
              item-text="title"
              @input="displayList"
              outlined
            ></v-select>
            <div class="p-12 bg-gray-100 border border-gray-300">
              <uploadFile
                v-if="fileType == 'ACD'"
                :fileProps="this.fileACD"
                name="ACD"
                :maxNumberFileProps="maxNumberFileACD"
                :fileRequired="fileRequired"
                @maxNumberFile="CheckNumberFile"
                @FileData="retrieveFile"
              />
              <uploadFile
                v-if="fileType == 'Contrat'"
                :fileProps="this.fileContrat"
                name="Contrat"
                :maxNumberFileProps="maxNumberFileContrat"
                :fileRequired="fileRequired"
                @maxNumberFile="CheckNumberFile"
                @FileData="retrieveFile"
              />
              <uploadFile
                v-if="fileType == 'Facture'"
                :fileProps="this.fileFacture"
                name="Facture"
                :maxNumberFileProps="maxNumberFileFacture"
                :fileRequired="fileRequired"
                @maxNumberFile="CheckNumberFile"
                @FileData="retrieveFile"
              />
              <listFile
                :isVisible="hasACD"
                :file="this.fileACD"
                @remove="refreshListFiles"
              />
              <listFile
                :isVisible="hasContrat"
                :file="this.fileContrat"
                @remove="refreshListFiles"
              />
              <listFile
                :isVisible="hasFacture"
                :file="this.fileFacture"
                @remove="refreshListFiles"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-if="siretFound">
        <!-- commentaire -->
        <v-col cols="10" sm="8" md="8" lg="6" xl="6">
          <v-subheader>Ajouter une note :</v-subheader>

          <v-textarea
            solo
            no-resize
            name="comment"
            label="Commentaire"
            v-model="comment"
          ></v-textarea>
        </v-col>
      </v-row>
      <!-- button submit -->
      <v-row align="center" justify="center" v-if="siretFound">
        <v-col class="ml-16 mt-0" cols="10" sm="8" md="8" lg="6">
          <v-btn
            @click="submitForm"
            depressed
            elevation="1"
            large
            :color="$colors[0]"
            class="ma-auto text-white"
            width="90%"
          >
            <span class="Poppins-Bold">Envoyer</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import loadingComponent from "../../components/ui/loading-component.vue";
import axios from "axios";
import uploadFile from "./uploadFile.vue";
import listFile from "./listFile.vue";
export default {
  name: "DemandeDeCotation",
  components: { uploadFile: uploadFile, listFile: listFile, loadingComponent },
  data() {
    return {
      fileType: "ACD",

      fileACD: [],
      fileContrat: [],
      fileFacture: [],

      maxNumberFileACD: false,
      maxNumberFileContrat: false,
      maxNumberFileFacture: false,
      hasACD: true,
      hasContrat: false,
      hasFacture: false,
      items: [{ title: "ACD" }, { title: "Contrat" }, { title: "Facture" }],

      //data for dialog
      dialog: false,
      error: false,
      loading: false,
      messageDialoge: "L'e-mail a été envoyé avec succès",
      //data pour recherche siret
      siretFound: false,
      raisonFound: false,
      postalFound: false,
      adressePostale: "",
      codeNaf: "",
      codeInsee: "",
      codePostale: "",
      raison: "",
      regie: false,
      traitemantEnedisRegie: "non",
      filelist: [],
      Segment: ["C5", "C4", "C3", "C2"],
      Profil: ["P011", "P012", "P013", "P014", "P015", "P016", "P017", "P018", "P019"],
      Tarif: ["T1", "T2", "T3", "T4"],
      energie: "ELEC",
      siret: "",
      num_comp: [""],
      lengthCompteur: 0,
      //marge: null,
      Civilite: "M.",
      nom: "",
      prenom: "",
      email: "",
      numTele: "",
      fonction: "",
      echeance: null,
      fin_du_contrat: null,
      dateCotationSouhaite: null,
      comment: "",
      formElec: {
        typeOffre: [],
      },
      formGaz: {},
      rules: {
        isString: (value) =>
          !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        hasWhiteSpace: (value) =>
          !/\s/.test(value) || "Ce champ ne doit pas être constitué d'espace",
        required: (value) => !!value || "Ce champ est obligatoire.",
        siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
        isNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

        emailRules: (v) => (!!v && /.+@.+\.+.+/.test(v)) || "E-mail invalide",
      },
      fileRequired: false,
    };
  },
  created() {
    this.typeUser = localStorage.getItem("typeUser");
  },

  methods: {
    CheckNumberFile(e) {
      switch (this.hasACD || this.hasContrat || this.hasFacture) {
        case this.hasACD:
          this.maxNumberFileACD = e;
          break;
        case this.hasContrat:
          this.maxNumberFileContrat = e;
          break;
        case this.hasFacture:
          this.maxNumberFileFacture = e;
          break;
        default:
          break;
      }
    },
    displayList(e) {
      switch (e) {
        case "ACD":
          (this.hasACD = true), (this.hasContrat = false), (this.hasFacture = false);
          break;
        case "Contrat":
          (this.hasACD = false), (this.hasContrat = true), (this.hasFacture = false);
          break;
        case "Facture":
          (this.hasACD = false), (this.hasContrat = false), (this.hasFacture = true);
          break;
        default:
          break;
      }
    },
    retrieveFile(e) {
      switch (this.hasACD || this.hasContrat || this.hasFacture) {
        case this.hasACD:
          this.fileACD = e;
          this.fileRequired = false;
          break;
        case this.hasContrat:
          this.fileContrat = e;
          this.fileRequired = false;
          break;
        case this.hasFacture:
          this.fileFacture = e;
          this.fileRequired = false;
          break;
        default:
          break;
      }
    },

    // debut dans l'ajoute et suppression d'un compteur dans le formulaire
    ajouterCompteur() {
      this.num_comp.push("");
    },
    supprimerCompteur(index) {
      this.num_comp.splice(index, 1);
    },
    rechercheParSiret() {
      if (this.getToken("token")) {
        let self = this;
        self.siretFound = false;
        self.raisonFound = false;
        self.postalFound = false;
        this.$refs.form.validate();
        if (this.$refs.form.validate()) {
          this.dialog = true;
          this.loading = true;
          this.error = false;
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "getDonneParSiret/",
            method: "POST",
            data: { siret: this.siret, token: this.getToken("token") },
          })
            .then((res) => {
              if (res.data.result === "permission") {
                localStorage.removeItem("vendeurName");
                localStorage.removeItem("typeUser");
                localStorage.removeItem("token");
                this.$router.push("/login");
              }
              if (res.data.result == true) {
                self.siretFound = true;
                self.raison = res.data.raison;
                if (self.raison != "" && self.raison != null) {
                  self.raisonFound = true;
                }
                self.adressePostale = res.data.adresse;

                self.codeNaf = res.data.codeNaf;
                this.codeInsee = res.data.codeInsee;
                this.regie = res.data.regie;
                this.loading = false;
                this.dialog = false;
              } else {
                self.loading = false;
                this.dialog = true;
                this.error = true;
                this.messageDialoge = "Le siret est incorrect";
                self.siretFound = false;
                this.raisonFound = false;
                self.raison = "";
                self.siret = "";
                self.adressePostale = "";
                self.codeInsee = "";
                self.codeNaf = "";
              }
            })
            .catch((err) => {
              self.siretFound = false;
              this.raisonFound = false;
              this.postalFound = false;
              self.raison = "";
              self.siret = "";
              self.adressePostale = "";
              self.codePostale = "";
              self.codeInsee = "";
              self.codeNaf = "";

              self.loading = false;
              this.dialog = true;
              this.error = true;
              this.messageDialoge = "Le siret est incorrect";
            });
        }
      } else {
        this.$router.push("/login");
      }
    },
    ajouterDemandeCotation() {
      let self = this;
      this.fileRequired = false;
      var formData = new FormData();
      formData.append("traitemantEnedisRegie", this.traitemantEnedisRegie);
      formData.append("typeDemande", this.energie);
      var nbrFile = this.filelist.length;

      formData.append("siret", this.siret);
      formData.append("raison", this.raison);
      formData.append("adresse", this.adressePostale);
      formData.append("fonction", this.fonction);
      formData.append("Civilite", this.Civilite);
      formData.append("nom", this.nom);
      formData.append("prenom", this.prenom);
      formData.append("email", this.email);
      formData.append("numTele", this.numTele);

      for (var i = 0; i < this.fileACD.length; i++) {
        formData.append("ACD" + i, this.fileACD[i]);
      }

      for (var i = 0; i < this.fileContrat.length; i++) {
        formData.append("Contrat" + i, this.fileContrat[i]);
      }

      for (var i = 0; i < this.fileFacture.length; i++) {
        formData.append("Facture" + i, this.fileFacture[i]);
      }

      formData.append("numCompteur", this.num_comp);
      formData.append("Echeance", this.echeance);
      formData.append("comment", this.comment);
      formData.append("finContrat", this.fin_du_contrat);
      formData.append("dateCotationSouhaite", this.dateCotationSouhaite);
      formData.append("typeOffre", this.formElec.typeOffre);
      formData.append("token", this.getToken("token"));
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "ajouterDemandeDeCotation/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == false || res.data.result === "permission") {
            self.loading = false;
            this.dialog = true;
            this.error = true;
            this.messageDialoge = "Demande incorrecte - Veuillez verifier vos données";
            if (res.data.result === "permission") {
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          } else if (res.data.result == "dajaTrouver") {
            self.dialog = false;
            self.loading = false;
            self.dialog = true;
            self.error = true;
            this.messageDialoge =
              "ce numéro de compteur (" +
              res.data.compteurError +
              ") est déjà associé à un autre compte";
          } else if (res.data.result == true) {
            self.dialog = false;
            self.loading = false;
            self.dialog = true;
            self.error = false;
            /*this.envoyerNotification(
              "------------------- -----------------\n Nouvelle demande de cotation ELEC envoyer PAR " +
                localStorage.getItem("email") +
                " \n societe: " +
                this.raison +
                " \n N°compteur: " +
                this.num_comp +
                "\n -------------------------------------------"
            );*/

            this.messageDialoge = "L'e-mail a été envoyé avec succès";
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          self.loading = false;
          this.dialog = true;
          this.error = true;
          this.messageDialoge = "Demande incorrecte - Veuillez verifier vos données";
        });
    },
    submitForm() {
      let self = this;
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.dialog = true;
        this.loading = true;
        this.error = false;
        if (this.energie == "ELEC") {
          if (this.formElec.typeOffre.length > 0) {
            if (
              this.fileACD.length > 0 ||
              this.fileContrat.length > 0 ||
              this.fileFacture.length > 0
            ) {
              if (this.traitemantEnedisRegie == "non") {
                var formDonne = new FormData();
                formDonne.append("numCompteur", this.num_comp);
                formDonne.append("token", this.getToken("token"));
                axios({
                  url: process.env.VUE_APP_URL_API_CLIENT + "verifCompteurExist/",
                  method: "POST",
                  data: formDonne,
                })
                  .then((res) => {
                    if (res.data.result === "permission") {
                      localStorage.removeItem("vendeurName");
                      localStorage.removeItem("typeUser");
                      localStorage.removeItem("token");
                      this.$router.push("/login");
                    }
                    if (res.data[0].result === "valide") {
                      this.ajouterDemandeCotation();
                    } else if (res.data[0].result === "notValide") {
                      self.dialog = false;
                      self.loading = false;
                      self.error = false;
                      self.enedisRecherchePointResult = res.data[0].result;
                      self.missedCompteur = res.data[0].compteurError;
                      self.dialog = true;
                      self.error = true;
                      self.messageDialoge =
                        "le compteur n°" + self.missedCompteur + " n'existe pas";
                    } else if (res.data[0].result === "dataErreur") {
                      self.dialog = false;
                      self.loading = false;
                      self.error = false;
                      self.enedisRecherchePointResult = res.data[0].result;
                      self.dialog = true;
                      self.error = true;
                      self.messageDialoge = "veuillez vérifier vos données.";
                    }
                  })
                  .catch((err) => {
                    self.dialog = false;
                    self.loading = false;
                    self.error = false;
                    console.log(err);
                  });
              } else {
                this.ajouterDemandeCotation();
              }
            } else {
              this.fileRequired = true;
              self.loading = false;
              this.dialog = true;
              this.error = true;
              this.messageDialoge = "Sélectionnez entre 1 et 5 fichier";
            }
          } else {
            self.loading = false;
            this.dialog = true;
            this.error = true;
            this.messageDialoge = "Sélectionnez au moins un type d'offre";
            if (this.filelist.length > 0 && this.filelist.length <= 5) {
              this.fileRequired = false;
            } else {
              this.fileRequired = true;
            }
          }
        } else if (this.energie == "GAZ") {
          if (
            this.fileACD.length > 0 ||
            this.fileContrat.length > 0 ||
            this.fileFacture.length > 0
          ) {
            this.ajouterDemandeCotation();
          } else {
            this.fileRequired = true;
            self.loading = false;
            this.dialog = true;
            this.error = true;
            this.messageDialoge = "Sélectionnez entre 1 et 3 fichier";
          }
        }
      }
    },
    onChange() {
      this.filelist.push(this.$refs.file.files);
    },
    refreshListFiles(file) {
      switch (file) {
        case this.fileACD:
          this.fileACD = file;
          this.maxNumberFileACD = false; // obligatoirement false dans notre cas car en enlevant un element d'une liste de 3 ou sera en dessous de length de 3
          break;
        case this.fileContrat:
          this.fileContrat = file;
          this.maxNumberFileContrat = false;
          break;
        case this.fileFacture:
          this.fileFacture = file;
          this.maxNumberFileFacture = false;
          break;
        default:
          break;
      }
    },
    async envoyerNotification(message) {
      var formData = new FormData();
      formData.append("msg", message);
      axios({
        url: "https://flaskapi.gaz-elec-moinscher.fr/slackBotSendMessage/",
        method: "POST",
        data: formData,
      }).then((res) => {});
    },
  },
};
</script>
<style scoped>
::v-deep .v-label {
  margin: 0px;
}
</style>
<style scoped>
@media only screen and (max-width: 400px) {
  ::v-deep .main-container {
    margin-left: 0px !important;
  }
}
</style>
<style scoped>
@media only screen and (max-width: 500px) {
  ::v-deep .v-dialog > .v-card > .v-card__title {
    font-size: 1.25em !important;
  }
}
</style>
<style>
@media only screen and (max-width: 400px) {
  .title {
    font-size: small;
  }
}
</style>
<style>
.v-input--selection-controls__input + .v-label,
.v-input--radio-group legend.v-label {
  color: black;
  font-family: "Poppins-Bold";
  src: url("../../components/ui/fonts/Poppins-Bold.ttf") format("truetype");
}
</style>
